import React from 'react'
import { Field, change } from 'redux-form'
import { getInsuranceFromRPACompanies } from '../../../../containers/authorised/addOns/other/AddOnsHelperFunctions';
import Alert from '../../../../itrust_common/components/Alert';
import { connect } from 'react-redux';
import { rpaClaimFormReset } from '../../../../redux/actions/addOns/rpa/rpaAction';
import { claimFormReset } from '../../../../redux/actions/order/claimFormActions';
import { tabChange } from '../../../../itrust_common/redux/actions/otherActions';
import { AutoCompleteSelect } from '../../../../itrust_common/components/form';
import RPAInsuranceVerify from '../../../../containers/authorised/addOns/rpa/eligibilityVerify/RPAInsuranceVerify';
import { getInsuranceType } from '../../../../itrust_common/components/HelperFunctions';

function OrderInsurancePayer(props) {
  const { companies, claimFormData, order, dispatch, activeTab, rpaConfig } = props

  const handleOnInsuranceChange = async (data) => {
    const selectedInsuranceType = data?.insuranceType.slice(0, 13) === 'PatientVision' ? 'vision' : 'medical';
    const existingInsuranceType = order.patient_insurance_attributes?.insurance_type.slice(0, 13) === 'PatientVision' ? 'vision' : 'medical';
    const isSameInsuranceType = selectedInsuranceType === existingInsuranceType;
    let showAlert = false;
    let alertText = '';
    if (isSameInsuranceType) {
      if (rpaConfig?.status && rpaConfig?.status !== 'inactive' && selectedInsuranceType === 'vision') {
        const selectedInsuranceIsRPA = getInsuranceFromRPACompanies(companies, data?.insuranceName || order.patient_insurance_attributes?.insurance_name);
        const previousInsuranceIsRPA = getInsuranceFromRPACompanies(companies, order.patient_insurance_attributes?.insurance_name);
        if (selectedInsuranceIsRPA || previousInsuranceIsRPA) {
          showAlert = true;
          alertText = 'You are changing Vision insurance from or to an RPA Insurance.';
        }
      }
    } else if (!isSameInsuranceType) {
      showAlert = true;
      alertText = 'You are changing insurance from Vision to Medical.';
    }
    if (claimFormData.id && showAlert) {
      try {
        const confirmed = await new Promise((resolve, reject) => {
          Alert.confirm(`Claim form for this order exists. ${alertText} Clicking 'Continue' will delete the existing claim form.`, (res) => {
            if (res) {
              handleAllowClaimDelete(data);
              resolve(true);
            } else {
              reject(false);
            }
          });
        });
        return confirmed;
      } catch {
        return false;
      }
    } else {
      if (!data) {
        // Reset Case
        dispatch(change('orderHeaderForm', 'patient_insurance_id', null));
        dispatch(change('orderHeaderForm', 'patient_insurance_name', null));
      }
      return true;
    }
  };

  const handleAllowClaimDelete = (data) => {
    // const { activeTab } = this.props;
    dispatch(change('orderHeaderForm', `is_destroy_claim_form`, true));
    dispatch(claimFormReset())
    dispatch(rpaClaimFormReset())
    activeTab === 6 && dispatch(tabChange({ order: 1 }))
    if (!data) {
      // Reset Case
      dispatch(change('orderHeaderForm', 'patient_insurance_id', null));
      dispatch(change('orderHeaderForm', 'patient_insurance_name', null));
    }
  }

  const insuranceOptions = () => {
    const patientAttributes = order.patient_attributes
    let options = []
    const insuranceTypes = ['patient_vision_insurance', 'patient_medical_insurance', 'patient_secondary_insurance', 'patient_tertiary_insurance', 'patient_vision_secondary_insurance', 'patient_vision_tertiary_insurance']
    insuranceTypes.map(insuranceType => {
      if (patientAttributes[insuranceType]) {
        options = [...options, { insuranceType: patientAttributes[insuranceType].insurance_type, insuranceName: patientAttributes[insuranceType]?.insurance_name, id: patientAttributes[insuranceType]?.id }]
      }
    })
    return options;
  }

  return (
    <>
      <Field
        name="patient_insurance_id"
        label="Insurance Payer"
        component={AutoCompleteSelect}
        data={insuranceOptions()}
        onSelectChange={(data) => handleOnInsuranceChange(data)}
        onResetVal={() => handleOnInsuranceChange()}
        displayName="patient_insurance_name"
        textField="insuranceName"
        valueField="id"
        hideSearch={true}
        key={order.id}
        wrapperClasses="form-group col-3"
      />

      {rpaConfig?.visible && <RPAInsuranceVerify
        selectedInsuranceName={order.patient_insurance_attributes?.insurance_name}
        selectedInsuranceMemberId={order.patient_insurance_attributes?.member_id}
        insuranceType={getInsuranceType(order.patient_insurance_attributes?.insurance_type)}
        orderId={order.id}
        patientId={order.patient_id}
        order={order}
        isOrderView={true}
        wrapperClasses="col-2 pl-0 align-items-center"
      />}
    </>
  )
}


const mapStateToProps = (state) => {
  const { organisation: { organisation }, rpa: { companies }, order: { order } } = state

  return {
    rpaConfig: organisation.add_ons_config?.type?.rpa,
    companies,
    order,
  }
}

export default connect(mapStateToProps)(OrderInsurancePayer)