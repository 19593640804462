import { findByKey, formatPhoneNumber } from "../../../../itrust_common/components/HelperFunctions"

export function getAddOnDefaultServiceURL(addOnsConfig) {
  if (addOnsConfig?.itrust_pay?.visible) {
    return '/add-ons/itrust-pay/dashboard'
  }
  else if (addOnsConfig?.voip?.visible) {
    return '/add-ons/voip/settings'
  }
  else if (addOnsConfig?.two_way_message?.visible) {
    return '/add-ons/two-way-message/dashboard'
  }
  else {
    return '/dashboard/overview'
  }
}

export const getCallStatusSymbol = (call_log) => {
  if (call_log.call_type === 'outgoing') {
    return <span><i className="las la-arrow-up rotate-45 font-size-20"></i></span>
  }
  else if (call_log.call_type === 'incoming' && call_log.status === 'no-answer') {
    return <span><i className="las la-level-up-alt rotate-45 font-size-18 text-danger"></i></span>
  }
  else {
    return <span><i className="las la-arrow-up rotate-220 text-success font-size-20"></i></span>
  }
}

export const getCallStatus = (call_log) => {
  if (call_log.status !== "completed") {
    switch (call_log.status) {
      case "no-answer":
        return call_log.call_type === 'incoming' ? <span className='text-danger'>Missed Call</span> : <span className='text-danger'>No Answer</span>
      case "canceled":
        return <span className='text-danger'>Declined Call</span>
      case "initiated":
        return <span className='text-warning'>Initiated</span>
      case null:
        return <span className='text-danger'>Missed</span>
      default: return <span className='text-danger'>{`${call_log.status}`}</span>
    }
  }
  else {
    if (call_log.call_type === 'incoming') {
      return <span className='text-dark'>Incoming Call</span>
    }
    else {
      return <span className='text-dark'>Outgoing Call</span>
    }
  }
}

export const getPatientPhoneNumber = (callLog) => {
  if (callLog.call_type === "incoming") {
    return formatPhoneNumber(callLog.from)
  }
  else {
    // outgoing
    return formatPhoneNumber(callLog.to)
  }
}

// RPA
export const isRpaVerifiedInsurance = (rpaConfig, companies, insurance_name) => {
  const company = companies.find((item) => item.config.insurance_payer == insurance_name)
  return (rpaConfig?.status && rpaConfig?.status !== 'inactive' && company && company?.config?.creds?.status === 'success')
}

export const getInsuranceFromRPACompanies = (companies, insurance_name) => {
  const insurance = companies.find((item) => item.config.insurance_payer == insurance_name)
  return insurance
}
