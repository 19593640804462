import { addOnReducer } from "./addOnReducer";
import { voipReducer } from "./voip/voipReducer";
import { contactReducer } from "./contactReducer";
import { itrustPayReducer } from "./itrustPay/itrustPayReducer";
import { twoWayReportReducer } from "./twoWayMessaging/twoWayReportReducer";
import { itrustPayPaymentLogsReducer } from "./itrustPay/itrustPayPaymentLogsReducer";
import { voipReportReducer } from "./voip/voipReportReducer";
import { EDIReportReducer } from "./EDIClearingHouse/EDIReportReducer";
import { whatsAppReducer } from "./whatsApp/whatsAppReducer";
import { whatsAppReportReducer } from "./whatsApp/whatsAppReportReducer";
import { rpaReducer } from "./rpa/rpaReducer";
import { rpaReportReducer } from "./rpa/rpaReportReducer";
import { tabReducer } from "./tab/tabReducer";

const addOnsReducers = {
  addOn: addOnReducer,
  voip: voipReducer,
  voipReport: voipReportReducer,
  itrustPay: itrustPayReducer,
  itrustPayPaymentLog: itrustPayPaymentLogsReducer,
  twoWayReport: twoWayReportReducer,
  contact: contactReducer,
  ediReport: EDIReportReducer,
  whatsApp: whatsAppReducer,
  whatsAppReport: whatsAppReportReducer,
  rpa: rpaReducer,
  rpaReport: rpaReportReducer,
  tab: tabReducer,
};

export default addOnsReducers;